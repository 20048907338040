import { Empty, Popconfirm } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { ConfirmationSendMailModal } from '../../../../components/Common/ConfirmationSendMailModal';
import * as S from './styles';
import { Step5Data, Step5Props } from './types';
import { useSend } from './useSend';

import StyledButton from '../../../../components/Common/StyledButton';
import { StyledLoading } from '../../../../components/Common/StyledLoading';
import StyledModal from '../../../../components/Common/StyledModal';

export function Send({ bidId, previousStep, onCancel, originalBidId }: Step5Props) {
  const { t } = useTranslation();
  const {
    bitSendModal,
    step5Data,
    loading,
    setBitSendModal,
    fetchStepSendData,
    renderContent,
    sendEmail,
    openPopConfirm,
    setOpenPopConfirm,
    handleSendEmail,
  } = useSend(bidId, originalBidId);

  const history = useHistory();

  useEffect(() => {
    fetchStepSendData();
  }, []);

  return (
    <>
      <S.ContentForm>
        <StyledModal
          title={t('pages.newRfq.steps.send.modal.title')}
          open={bitSendModal}
          body={
            <ConfirmationSendMailModal
              title={t('pages.newRfq.steps.send.modal.congratulations')}
              subTitle={t('pages.newRfq.steps.send.modal.selectedItens')}
            />
          }
          width="45rem"
          footer={null}
          onClose={() => {
            setBitSendModal(false);
            history.push('/rfq');
          }}
        />

        {loading ? (
          <StyledLoading height={50} />
        ) : step5Data.length > 0 ? (
          renderContent(step5Data)
        ) : (
          <Empty />
        )}
      </S.ContentForm>
      <S.StepFooter>
        <StyledButton variant="slim" onClick={() => onCancel()}>
          {t('common.cancel')}
        </StyledButton>
        <S.StepButtonContainer>
          <StyledButton variant="secondary" onClick={() => previousStep()}>
            {t('common.previous')}
          </StyledButton>
          <Popconfirm
            title={t('pages.newRfq.steps.send.popConfirmation.title')}
            description={t('pages.newRfq.steps.send.popConfirmation.description')}
            onClick={() => null}
            placement="topLeft"
            onConfirm={(e) => handleSendEmail()}
            open={openPopConfirm}
            onOpenChange={() => {
              if (originalBidId) {
                setOpenPopConfirm(true);
                return;
              }
              handleSendEmail();
            }}
            onCancel={() => setOpenPopConfirm(false)}
          >
            <StyledButton
              variant="primary"
              disabled={step5Data.some((item: Step5Data) => item.saved !== true) || loading}
            >
              {t('common.send')}
            </StyledButton>
          </Popconfirm>
        </S.StepButtonContainer>
      </S.StepFooter>
    </>
  );
}
