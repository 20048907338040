import { CloseOutlined } from '@ant-design/icons';
import { Flex, TableColumnsType, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { HttpStatusCode } from 'axios';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import StyledClassification from '../../../components/Common/StyledClassification';
import StyledFilterTag from '../../../components/Common/StyledFilterTag';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import StyledIndicationTag from '../../../components/IndicationTag';
import { useAlert } from '../../../context/AlertContext';
import { useCmsContext } from '../../../context/CmsContext';
import { formatDataToSelect, handleFormatValueFields } from '../../../helpers/nUtils';
import { getSortOrder, getURI } from '../../../helpers/utils';
import { api } from '../../../services/api';
import { services } from '../../../services/services';
import theme from '../../../styles/theme';
import * as S from './styles';
import { AwardListData, AwardListParams, IAwardReponse } from './types';

export function useAwardList(IdBid?: string, cmView?: boolean) {
  const { openAlert } = useAlert();
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();
  const initialParams: AwardListParams = {
    cm: handleSelectDefaultCm('Foxconn'),
    project: '',
    category: '',
    altGroup: '',
    qStatus: '',
    supplier: '',
    specification: '',
    partNumber: '',
    sortDirection: 'asc',
    sortField: '',
    page: 0,
    size: 10,
    totalElements: 0,
  };
  const { t } = useTranslation();
  const { id: bidId } = useParams<{ id: string }>();
  const [awardListData, setAwardListData] = useState<AwardListData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [params, setParams] = useState(initialParams);
  const [projectList, setProjectList] = useState<Array<StyledSelectOptions>>([]);
  const [categoryList, setCategoryList] = useState<Array<StyledSelectOptions>>([]);
  const [altgroupList, setAltgroupList] = useState<Array<StyledSelectOptions>>([]);
  const [qstatusList, setQstatusList] = useState<Array<StyledSelectOptions>>([]);
  const [supplierList, setSupplierList] = useState<Array<StyledSelectOptions>>([]);
  const [specificationList, setSpecificationList] = useState<Array<StyledSelectOptions>>([]);
  const [partNumberList, setPartNumberList] = useState<Array<StyledSelectOptions>>([]);
  const [isFirstRendering, setIsFirstRendering] = useState<boolean>(true);

  const fetchProjets = async () => {
    try {
      const { data, status }: { data: Array<string>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/award-simulation/filter`, {
          filterToReturn: 'project',
          cm: params.cm,
          id: bidId || IdBid,
        })
      );
      if (status === 200) {
        setProjectList(formatDataToSelect(data, true));
      }
      if (status === 204) {
        setProjectList([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchCategories = async () => {
    try {
      const { data, status }: { data: Array<string>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/award-simulation/filter`, {
          filterToReturn: 'category',
          cm: params.cm,
          project: params.project,
          id: bidId || IdBid,
        })
      );
      if (status === 200) {
        setCategoryList(formatDataToSelect(data, true));
      }
      if (status === 204) {
        setCategoryList([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchAltGroups = async () => {
    try {
      const { data, status }: { data: Array<string>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/award-simulation/filter`, {
          filterToReturn: 'group',
          cm: params.cm,
          project: params.project,
          category: params.category,
          id: bidId || IdBid,
        })
      );
      if (status === 200) {
        setAltgroupList(formatDataToSelect(data, true));
      }
      if (status === 204) {
        setAltgroupList([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchQStatus = async () => {
    try {
      const { data, status }: { data: Array<string>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/award-simulation/filter`, {
          filterToReturn: 'status',
          cm: params.cm,
          project: params.project,
          category: params.category,
          altGroup: params.altGroup,
          id: bidId || IdBid,
        })
      );
      if (status === 200) {
        setQstatusList(formatDataToSelect(data, true));
      }
      if (status === 204) {
        setQstatusList([]);
      }
    } catch (error: any) {
      console.log(error);
      openAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchSupplier = async () => {
    try {
      const { data, status }: { data: Array<string>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/award-simulation/filter`, {
          filterToReturn: 'supplier',
          cm: params.cm,
          project: params.project,
          category: params.category,
          altGroups: params.altGroup,
          qStatus: params.qStatus,
          id: bidId || IdBid,
        })
      );
      if (status === 200) {
        setSupplierList(formatDataToSelect(data, true));
      }
      if (status === 204) {
        setSupplierList([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchSpecs = async () => {
    try {
      const { data, status }: { data: Array<string>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/award-simulation/filter`, {
          filterToReturn: 'specification',
          cm: params.cm,
          project: params.project,
          category: params.category,
          altGroups: params.altGroup,
          qStatus: params.qStatus,
          supplier: params.supplier,
          id: bidId || IdBid,
        })
      );
      if (status === 200) {
        setSpecificationList(formatDataToSelect(data, true));
      }
      if (status === 204) {
        setSpecificationList([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchPartNumber = async () => {
    try {
      const { data, status }: { data: Array<string>; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/award-simulation/filter`, {
          filterToReturn: 'partNumber',
          cm: params.cm,
          project: params.project,
          category: params.category,
          altGroups: params.altGroup,
          qStatus: params.qStatus,
          supplier: params.supplier,
          specification: params.specification,
          id: bidId || IdBid,
        })
      );
      if (status === 200) {
        setPartNumberList(formatDataToSelect(data, true));
      }
      if (status === 204) {
        setPartNumberList([]);
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnList'));
    }
  };

  const fetchAwardListData = async () => {
    try {
      setIsLoading(true);
      const { data, status }: { data: IAwardReponse; status: HttpStatusCode } = await api.get(
        getURI(`${services.rfq}/award-simulation/details/${bidId || IdBid}`, {
          ...params,
          userCm: cmView,
        })
      );
      if (status === 200) {
        const formattedAwardList: AwardListData[] = [
          {
            group: 'Total',
            totalAward: String(data.totalAwardSum),
            isTotalizer: true,
          },
          ...data.awardlist,
        ];
        setParams((prev) => {
          return { ...prev, totalElements: data.totalElements };
        });
        return setAwardListData(formattedAwardList);
      }
      setAwardListData([]);
    } catch (error: any) {
      setAwardListData([]);
      openAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeTable = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AwardListData>
  ) => {
    setParams({
      ...params,
      sortField: sorter.field as Key,
      sortDirection: getSortOrder(sorter.order).toLowerCase(),
    });
  };

  const changePage = (page: number, pageSize: number) => {
    setParams({ ...params, page: page - 1, size: pageSize });
  };

  const columns: TableColumnsType<AwardListData> = [
    {
      title: t('pages.awardsSummary.awardsList.labels.altGroup'),
      dataIndex: 'group',
      key: 'group',
      align: 'left',
      width: 250,
      render: (value: string, record) => {
        return <S.AltGroupContainer>{value}</S.AltGroupContainer>;
      },
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.supplier'),
      dataIndex: 'supplier',
      key: 'supplier',
      align: 'left',
      width: 185,
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.ranking'),
      dataIndex: 'rankingPosition',
      key: 'rankingPosition',
      align: 'center',
      width: 185,
      render: (value: string, record) => {
        return record.group === 'Total' ? null : (
          <Flex align="center" justify="center">
            {record.rankingPosition ? (
              <StyledClassification position={record.rankingPosition} />
            ) : (
              '-'
            )}
          </Flex>
        );
      },
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.category'),
      dataIndex: 'category',
      key: 'category',
      align: 'left',
      width: 185,
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.specification'),
      dataIndex: 'specification',
      key: 'specification',
      align: 'left',
      width: 185,
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.cm'),
      dataIndex: 'cm',
      key: 'cm',
      align: 'left',
      width: 185,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.project'),
      dataIndex: 'project',
      key: 'project',
      align: 'left',
      width: 185,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.qStatus'),
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 185,
      render: (value: string, record) => {
        return record.group === 'Total' ? null : <StyledIndicationTag text={value} />;
      },
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.partNumber'),
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'left',
      width: 185,
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.awardQty'),
      dataIndex: 'awardQty',
      key: 'awardQty',
      align: 'left',
      width: 185,
      sortDirections: ['ascend', 'descend'],
      sorter: true,
      render: (_, record) => {
        if (!record.isTotalizer) {
          return (
            <span>
              {handleFormatValueFields({
                number: record.awardQty,
                maxFactional: 0,
                minFractional: 0,
              })}
            </span>
          );
        }
      },
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.awardPercentage'),
      dataIndex: 'awardPercentage',
      key: 'awardPercentage',
      align: 'left',
      width: 185,
      render: (_, record) => {
        if (!record.isTotalizer) {
          return (
            <span>
              {handleFormatValueFields({
                number: record.awardPercentage,
                maxFactional: 2,
                minFractional: 2,
              })}
            </span>
          );
        }
      },
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.grossPrice'),
      dataIndex: 'grossPrice',
      key: 'grossPrice',
      align: 'left',
      width: 185,
      render: (_, record) => {
        if (!record.isTotalizer) {
          return (
            <span>
              ${' '}
              {handleFormatValueFields({
                number: record.grossPrice,
                maxFactional: 2,
                minFractional: 2,
              })}
            </span>
          );
        }
      },
    },
    {
      title: t('pages.awardsSummary.awardsList.labels.totalAward'),
      dataIndex: 'totalAward',
      key: 'totalAward',
      align: 'left',
      width: 185,
      render: (value: string, record) => {
        return record.group === 'Total' ? (
          <S.AltGroupContainer>
            $
            {handleFormatValueFields({
              number: record.totalAward,
              maxFactional: 2,
              minFractional: 2,
            })}
          </S.AltGroupContainer>
        ) : (
          `$${handleFormatValueFields({
            number: record.totalAward,
            maxFactional: 2,
            minFractional: 2,
          })}`
        );
      },
      sortDirections: ['ascend', 'descend'],
      sorter: true,
    },
  ];

  const handleUpdateFilters = (field: string, value: string) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const handleRenderClearAllFilters = () => {
    if (
      params.cm ||
      params.project ||
      params.category ||
      params.altGroup ||
      params.qStatus ||
      params.supplier ||
      params.specification ||
      params.partNumber
    ) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.cm && (
          <StyledFilterTag
            label={t('pages.awardsSummary.awardsList.labels.cm')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.cm}
            closeble
            onClose={() => handleUpdateFilters('cm', '')}
          />
        )}
        {params.project && (
          <StyledFilterTag
            label={t('pages.awardsSummary.awardsList.labels.project')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.project}
            closeble
            onClose={() => handleUpdateFilters('project', '')}
          />
        )}
        {params.category && (
          <StyledFilterTag
            label={t('pages.awardsSummary.awardsList.labels.category')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.category}
            closeble
            onClose={() => handleUpdateFilters('category', '')}
          />
        )}
        {params.altGroup && (
          <StyledFilterTag
            label={t('pages.awardsSummary.awardsList.labels.altGroup')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.altGroup}
            closeble
            onClose={() => handleUpdateFilters('altGroup', '')}
          />
        )}
        {params.qStatus && (
          <StyledFilterTag
            label={t('pages.awardsSummary.awardsList.labels.qStatus')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.qStatus}
            closeble
            onClose={() => handleUpdateFilters('qStatus', '')}
          />
        )}
        {params.supplier && (
          <StyledFilterTag
            label={t('pages.awardsSummary.awardsList.labels.supplier')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.supplier}
            closeble
            onClose={() => handleUpdateFilters('supplier', '')}
          />
        )}
        {params.specification && (
          <StyledFilterTag
            label={t('pages.awardsSummary.awardsList.labels.specification')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.specification}
            closeble
            onClose={() => handleUpdateFilters('specification', '')}
          />
        )}
        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.awardsSummary.awardsList.labels.partNumber')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.partNumber}
            closeble
            onClose={() => handleUpdateFilters('partNumber', '')}
          />
        )}
      </>
    );
  };

  const headerCsv = [
    { label: t('pages.awardsSummary.awardsList.labels.altGroup'), key: 'group' },
    { label: t('pages.awardsSummary.awardsList.labels.supplier'), key: 'supplier' },
    { label: t('pages.awardsSummary.awardsList.labels.ranking'), key: 'rankingPosition' },
    { label: t('pages.awardsSummary.awardsList.labels.category'), key: 'category' },
    { label: t('pages.awardsSummary.awardsList.labels.specification'), key: 'specification' },
    { label: t('pages.awardsSummary.awardsList.labels.cm'), key: 'cm' },
    { label: t('pages.awardsSummary.awardsList.labels.project'), key: 'project' },
    { label: t('pages.awardsSummary.awardsList.labels.qStatus'), key: 'status' },
    { label: t('pages.awardsSummary.awardsList.labels.partNumber'), key: 'partNumber' },
    { label: t('pages.awardsSummary.awardsList.labels.awardQty'), key: 'awardQty' },
    { label: t('pages.awardsSummary.awardsList.labels.awardPercentage'), key: 'awardPercentage' },
    { label: `${t('pages.awardsSummary.awardsList.labels.grossPrice')} $`, key: 'grossPrice' },
    { label: `${t('pages.awardsSummary.awardsList.labels.totalAward')} $`, key: 'totalAward' },
  ];

  const transformData = (dataSource: AwardListData[]) => {
    const rows: AwardListData[] = [];
    dataSource.forEach((item) => {
      rows.push({
        group: item.group,
        supplier: item.supplier,
        rankingPosition: item.rankingPosition,
        category: item.category,
        specification: item.specification,
        cm: item.cm,
        project: item.project,
        status: item.status,
        partNumber: item.partNumber,
        awardPercentage: item.awardPercentage,
        grossPrice: item.grossPrice,
        totalAward: item.totalAward,
      });
    });
    return rows;
  };

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        partNumber: '',
        page: 0,
      };
    });
    Promise.all([fetchPartNumber()]);
  }, [params.specification]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        specification: '',
        partNumber: '',
        page: 0,
      };
    });
    Promise.all([fetchSpecs(), fetchPartNumber()]);
  }, [params.supplier]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        supplier: '',
        specification: '',
        partNumber: '',
        page: 0,
      };
    });
    Promise.all([fetchSupplier(), fetchSpecs(), fetchPartNumber()]);
  }, [params.qStatus]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        qStatus: '',
        supplier: '',
        specification: '',
        partNumber: '',
        page: 0,
      };
    });
    Promise.all([fetchQStatus(), fetchSupplier(), fetchSpecs(), fetchPartNumber()]);
  }, [params.altGroup]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        altGroup: '',
        qStatus: '',
        supplier: '',
        specification: '',
        partNumber: '',
        page: 0,
      };
    });
    Promise.all([
      fetchAltGroups(),
      fetchQStatus(),
      fetchSupplier(),
      fetchSpecs(),
      fetchPartNumber(),
    ]);
  }, [params.category]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        category: '',
        altGroup: '',
        qStatus: '',
        supplier: '',
        specification: '',
        partNumber: '',
        page: 0,
      };
    });
    Promise.all([
      fetchCategories(),
      fetchAltGroups(),
      fetchQStatus(),
      fetchSupplier(),
      fetchSpecs(),
      fetchPartNumber(),
    ]);
  }, [params.project]);

  useEffect(() => {
    if (isFirstRendering) return;
    setParams((prev) => {
      return {
        ...prev,
        project: '',
        category: '',
        altGroup: '',
        qStatus: '',
        supplier: '',
        specification: '',
        partNumber: '',
        page: 0,
      };
    });
    Promise.all([
      fetchProjets(),
      fetchCategories(),
      fetchAltGroups(),
      fetchQStatus(),
      fetchSupplier(),
      fetchSpecs(),
      fetchPartNumber(),
    ]);
  }, [params.cm]);

  useEffect(() => {
    fetchAwardListData();
  }, [params.sortDirection, params.sortField, params.page, params.size]);

  useEffect(() => {
    if ((bidId || IdBid) && isFirstRendering) {
      Promise.all([
        fetchProjets(),
        fetchCategories(),
        fetchAltGroups(),
        fetchQStatus(),
        fetchSupplier(),
        fetchSpecs(),
        fetchPartNumber(),
        fetchAwardListData(),
      ]).finally(() => {
        setIsFirstRendering(false);
      });
    }
  }, []);
  useEffect(() => {
    if (IdBid) {
      Promise.all([
        fetchProjets(),
        fetchCategories(),
        fetchAltGroups(),
        fetchQStatus(),
        fetchSupplier(),
        fetchSpecs(),
        fetchPartNumber(),
        fetchAwardListData(),
      ]);
    }
  }, [IdBid]);

  return {
    columns,
    CMs,
    awardListData,
    isLoading,
    projectList,
    categoryList,
    altgroupList,
    qstatusList,
    supplierList,
    specificationList,
    partNumberList,
    params,
    headerCsv,
    transformData,
    handleUpdateFilters,
    fetchAwardListData,
    handleRenderFiltersTags,
    handleRenderClearAllFilters,
    fetchAltGroups,
    fetchCategories,
    fetchPartNumber,
    fetchProjets,
    fetchSpecs,
    fetchSupplier,
    handleChangeTable,
    changePage,
  };
}
