import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IData } from '../../components/Qualification/QualificationStatus/types';
import { oneAlert } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import { IQualificationData } from './types';

export function useViewQualification() {
  const { pathname, state } = useLocation<{
    idQualification: string;
    partNumber: string;
    supplier: string;
  }>();

  const [isLoadingStatus, setIsLoadingStatus] = useState<boolean>(false);
  const [steps, setSteps] = useState<IData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [qualificationData, setQualificationData] = useState<IQualificationData>(
    {} as IQualificationData
  );

  const fetchStatus = async () => {
    try {
      setIsLoadingStatus(true);
      const { status, data } = await api.get(
        `${services.qualification}/summary/view/status/filter/${state.idQualification}`
      );
      if (status === 200) return setSteps(data);
      setSteps([]);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingStatus(false);
    }
  };

  const fetchQualificationData = async () => {
    try {
      setIsLoading(true);
      const { status, data } = await api.get(
        `${services.qualification}/summary/view/details/${state.idQualification}?partNumber=${state.partNumber}&supplier=${state.supplier}`
      );

      if (status === 200) {
        return setQualificationData(data);
      }
    } catch (error: any) {
      oneAlert('error', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Promise.all([fetchStatus(), fetchQualificationData()]);
  }, []);

  return {
    qualificationData,
    isLoading,
    isLoadingStatus,
    steps,
  };
}
