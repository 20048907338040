import { TourProps } from 'antd';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CardsPages } from './CardsPages';
import { IModuleProps } from './types';

export default function useHome() {
  const [openTour, setOpenTour] = useState<boolean>(false);
  const refCompanyReg = useRef(null);
  const refRFQSupplier = useRef(null);
  const refQualificationSupplier = useRef(null);
  const refFinance = useRef(null);
  const refMaterial = useRef(null);
  const refPPB = useRef(null);
  const refRFQAwards = useRef(null);
  const refStrategy = useRef(null);
  const history = useHistory();
  const [module, setModule] = useState<IModuleProps>({
    Finance: true,
    Material: false,
    PPB: false,
    RFQAwards: false,
    Strategy: false,
  });

  const stepsSupplier: TourProps['steps'] = [
    {
      title: t('pages.homeSupplier.titleCards.rfqCard'),
      description: t('pages.homeSupplier.messagesTour.stepTwo'),
      target: () => refRFQSupplier.current,
    },
    //TODO  Qualification ocultado em HML
    // {
    //   title: t('pages.homeSupplier.titleCards.qualificationCard'),
    //   description: t('pages.homeSupplier.messagesTour.stepThree'),
    //   target: () => refQualificationSupplier.current,
    // },
  ];

  const stepsCM: TourProps['steps'] = [
    {
      title: t('pages.homeCm.titleCards.financeCard'),
      description: t('pages.homeCm.messagesTour.finance'),
      target: () => refFinance.current,
    },
    {
      title: t('pages.homeCm.titleCards.materialCard'),
      description: t('pages.homeCm.messagesTour.material'),
      target: () => refMaterial.current,
    },
    {
      title: t('pages.homeCm.titleCards.ppbCard'),
      description: t('pages.homeCm.messagesTour.ppb'),
      target: () => refPPB.current,
    },
    {
      title: t('pages.homeCm.titleCards.rfqAwardsCard'),
      description: t('pages.homeCm.messagesTour.rfqAwards'),
      target: () => refRFQAwards.current,
    },
    {
      title: t('pages.homeCm.titleCards.strategyCard'),
      description: t('pages.homeCm.messagesTour.strategy'),
      target: () => refStrategy.current,
    },
  ];
  const saveTour = (value: boolean) => {
    setOpenTour(value);
    localStorage.setItem('tour', value.toString());
  };

  const handleFinanceCards = () => {
    return (
      <>
        <CardsPages title="Average" onClick={() => history.push('/average')} />
        <CardsPages title="Finance View" onClick={() => history.push('/dashboard/finance')} />
        <CardsPages
          title="Procurement View"
          onClick={() => history.push('/dashboard/procurement')}
        />
        <CardsPages title="Quotes" onClick={() => history.push('/quotes')} />
        <CardsPages title="Components Prices" onClick={() => history.push('/components-prices')} />
      </>
    );
  };
  const handleMaterialDataCards = () => {
    return (
      <>
        <CardsPages title="AVLC" onClick={() => history.push('/avlc')} />
        <CardsPages title="BOM" onClick={() => history.push('/boms')} />
        <CardsPages title="Components" onClick={() => history.push('/components')} />
        <CardsPages title="SKU" onClick={() => history.push('/sku')} />
        <CardsPages title="Inventory" onClick={() => history.push('/inventory')} />
      </>
    );
  };
  const handlePPBCards = () => {
    return (
      <>
        <CardsPages title="Simulation" onClick={() => history.push('/simulation')} />
        <CardsPages title="Scenarios" onClick={() => history.push('/secnarios')} />
        <CardsPages title="Demand Scenarios" onClick={() => history.push('/demands')} />
        <CardsPages title="Prices Scenarios" onClick={() => history.push('/prices')} />
      </>
    );
  };
  const handleStrategiesCards = () => {
    return (
      <>
        <CardsPages title="Share Proportion" onClick={() => history.push('/share-proportion')} />
        <CardsPages title="Demands " onClick={() => history.push('/new-demand')} />
      </>
    );
  };

  const handleSetModule = (moduleSelect: keyof IModuleProps) => {
    setModule((prevModule) => {
      const newModule = { ...prevModule };
      Object.keys(newModule).forEach((key) => {
        newModule[key as keyof IModuleProps] = key === moduleSelect;
      });
      return newModule;
    });
  };
  const handleRenderCardsPages = () => {
    if (module.Finance) {
      return handleFinanceCards();
    }
    if (module.Material) {
      return handleMaterialDataCards();
    }
    if (module.PPB) {
      return handlePPBCards();
    }
    if (module.Strategy) {
      return handleStrategiesCards();
    }

    return null;
  };

  useEffect(() => {
    if (!localStorage.getItem('tour')) {
      return setOpenTour(true);
    }
    setOpenTour(localStorage.getItem('tour') === 'true');
  }, []);
  return {
    refCompanyReg,
    refRFQSupplier,
    refQualificationSupplier,
    openTour,
    stepsSupplier,
    stepsCM,
    module,
    refFinance,
    refMaterial,
    refPPB,
    refRFQAwards,
    refStrategy,
    saveTour,
    handleSetModule,
    handleRenderCardsPages,
  };
}
