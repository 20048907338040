import {
  FolderViewOutlined,
  HourglassOutlined,
  SendOutlined,
  SnippetsOutlined,
  TrophyOutlined,
} from '@ant-design/icons';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import BoxSvg from '../../../assets/box.svg';
import { QualificationStatus } from '../../../helpers/enums';
import * as S from './styles';
import { IData, ISteps } from './types';

export function useQualificationStatus() {
  const { t } = useTranslation();

  const initialSteps: ISteps[] = [
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.requested')}</S.StatusTitle>,
      icon: <SnippetsOutlined />,
      key: QualificationStatus.REQUESTED,
      status: 'wait',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.sampleSubmissionPending')}</S.StatusTitle>,
      icon: <HourglassOutlined />,
      key: QualificationStatus.SAMPLE_SUBMISSION_PENDING,
      status: 'wait',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.samplesSent')}</S.StatusTitle>,
      icon: <SendOutlined />,
      key: QualificationStatus.SAMPLE_SENT,
      status: 'wait',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.receivedOdm')}</S.StatusTitle>,
      icon: <img src={BoxSvg} alt="Box" />,
      key: QualificationStatus.RECEIVED_BY_ODM,
      status: 'wait',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.testingProgress')}</S.StatusTitle>,
      icon: <FolderViewOutlined />,
      key: QualificationStatus.TESTING_IN_PROGRESS,
      status: 'wait',
    },
    {
      title: <S.StatusTitle>{t('pages.qualificationSteps.resultAvailable')}</S.StatusTitle>,
      icon: <TrophyOutlined />,
      key: QualificationStatus.RESULT_AVAILABLE,
      status: 'wait',
    },
  ];
  const [steps, setSteps] = useState(initialSteps);

  const formatSteps = (data: IData[]) => {
    const stepsCopy = initialSteps;
    let currentIndex = 0;

    data.forEach((item) => {
      currentIndex = steps.findIndex((step) => step.key === item.status);
      if (currentIndex > -1) {
        stepsCopy[currentIndex].status = 'finish';
        stepsCopy[currentIndex].description = (
          <div>
            <span>
              {dayjs(item.dateHour).format('MM.DD.YYYY ')} -{dayjs(item.dateHour).format('hh:mm a')}
            </span>
          </div>
        );
      }
    });

    setSteps(stepsCopy);
  };

  return {
    formatSteps,
    steps,
  };
}
