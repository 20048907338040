import { CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm, Space, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';
import EditableTableCell from '../../../components/Common/EditableTableCell';
import StyledFilterTag from '../../../components/Common/StyledFilterTag';
import StyledClearTag from '../../../components/Common/StyledFilterTag/StyledClearTag';
import { StyledSelectOptions } from '../../../components/Common/StyledSelect/types';
import { useModal } from '../../../components/Modal/useModal';
import SimpleTable from '../../../components/Tables/SimpleTable';
import { TableAction } from '../../../components/Tables/TableDropdownAction';
import { useAlert } from '../../../context/AlertContext';
import { useCmsContext } from '../../../context/CmsContext';
import { getURI } from '../../../helpers/utils';
import { api } from '../../../services/api';
import theme from '../../../styles/theme';
import ExpandableTable from '../ExpandableTable';
import * as S from './styles';
import {
  ItemTotalizer,
  PackingComponentsParams,
  PackingPriceItem,
  ProjectPackingItem,
} from './types';

export function usePackingPricePage() {
  const { openAlert } = useAlert();
  const { handleGetCms, handleSelectDefaultCm } = useCmsContext();
  const CMs = handleGetCms();
  const initialParams = {
    cm: handleSelectDefaultCm('Foxconn'),
    projectName: '',
    partNumber: '',
    spec: '',
    quarter: dayjs(),
  } as PackingComponentsParams;

  const [params, setParams] = useState(initialParams);

  const [projects, setProjects] = useState<Array<StyledSelectOptions>>([]);
  const [partOptions, setPartOptions] = useState<Array<StyledSelectOptions>>([]);
  const [specifications, setSpecification] = useState<Array<StyledSelectOptions>>([]);
  const [showError, setShowError] = useState(false);
  // const [selectPartNumber, setSelectPartNumber] = useState('');
  const [selectSpec, setSelectSpec] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dollarQuotation, setDollarQuotation] = useState('');
  const [monthImportedReferente, setMonthImportedReference] = useState('');
  const ExpandCategories: any = {};
  const [expandableViewStatus, setExpandableViewStatus] = useState(ExpandCategories);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [keyToEditRecord, setKeyToEditRecord] = useState('');
  const [packingPriceComponents, setPackingPriceComponents] = useState<Array<ProjectPackingItem>>(
    []
  );

  const [monthTitleColum1, setMonthTitleColum1] = useState('');
  const [monthTitleColum2, setMonthTitleColum2] = useState('');
  const [monthTitleColum3, setMonthTitleColum3] = useState('');
  const [visibleModalTax, openModalTax, closeModalTax] = useModal();
  const [modalTaxParams, setModalTaxParams] = useState({ componentType: '', spec: '' });

  const handleUpdateFilters = (field: string, value: any) => {
    setParams((oldParams) => {
      return {
        ...oldParams,
        [field]: value,
      };
    });
  };

  const clearAllFilters = () => {
    setParams(initialParams);
  };

  const handleRenderClearAllFilters = () => {
    if (params.projectName || params.partNumber || params.spec) {
      return <StyledClearTag onClick={clearAllFilters} />;
    }
  };

  const handleRenderFiltersTags = () => {
    return (
      <>
        {params.projectName && (
          <StyledFilterTag
            label={t('pages.packingPrices.filters.project')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.projectName}
            closeble
            onClose={() => {
              handleUpdateFilters('projectName', '');
            }}
          />
        )}

        {params.partNumber && (
          <StyledFilterTag
            label={t('pages.packingPrices.filters.PartNumber')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.partNumber}
            closeble
            onClose={() => {
              // setSelectPartNumber('');
              handleUpdateFilters('partNumber', '');
            }}
          />
        )}
        {params.spec && (
          <StyledFilterTag
            label={t('pages.packingPrices.filters.spec')}
            closeicon={<CloseOutlined color={theme.colors.prim} />}
            title={params.spec}
            closeble
            onClose={() => {
              setSelectSpec('');
              handleUpdateFilters('spec', '');
            }}
          />
        )}
      </>
    );
  };

  const handleUpdateExpandableViews = (value: string) => {
    setExpandableViewStatus((oldParams: any) => {
      return {
        ...oldParams,
        [value]: !expandableViewStatus[value],
      };
    });
  };

  const handleConvertDataToSelectOptionsData = (
    data: Array<string>
  ): Array<StyledSelectOptions> => {
    return data.map((item: string) => {
      return {
        label: item,
        value: item,
      };
    });
  };

  const handleFetchFiltersData = async () => {
    try {
      //retirando o quarter de dentro dos params pois não é aceito na request de filtros
      const { quarter, ...paramsNoQuarter } = params;
      const { data, status } = await api.get(
        getURI('/components/packaging/prices/header', paramsNoQuarter)
      );
      if (status === 200) {
        setPartOptions(handleConvertDataToSelectOptionsData(data.partNumberList) || []);
        setProjects(handleConvertDataToSelectOptionsData(data.projectNameList) || []);
        setSpecification(handleConvertDataToSelectOptionsData(data.specList) || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateTitleMontByQuarter = () => {
    try {
      const data = dayjs(params.quarter).startOf('quarter');
      setMonthTitleColum1(data.format('MMMM/YYYY'));
      setMonthTitleColum2(data.add(1, 'month').format('MMMM/YYYY'));
      setMonthTitleColum3(data.add(2, 'month').format('MMMM/YYYY'));
      return data.add(2, 'month').format('MMMM/YYYY');
    } catch (error) {
      console.log(error);
    }
  };

  const hasParams = () => {
    return params.projectName || params.spec || params.partNumber || params.quarter;
  };

  const handleFetchPackingPrices = async () => {
    try {
      if (!params.quarter) {
        setShowError(true);
        return;
      }

      setIsLoading(true);

      const { data, status } = await api.get(
        getURI('/components/packaging/prices', {
          ...params,
          quarter: `Q${dayjs(params.quarter).format('Q/YYYY')}`,
        })
      );
      setDollarQuotation(data.dolarCotation);
      setMonthImportedReference(data?.monthImportedReferente || '-');
      if (status === 200) {
        setPackingPriceComponents(data.projects || []);
        if (hasParams()) {
          setExpandableViewStatus({ [data.projects[0].projectName]: true });
        } else setExpandableViewStatus({});
      } else {
        setPackingPriceComponents([]);
      }
      handleCreateTitleMontByQuarter();
    } catch (error: any) {
      console.log(error);
      openAlert('error', error.message || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const prepareToEdit = (record: any) => {
    form.setFieldsValue({ ...record });
    setKeyToEditRecord(`${record.partNumber}-${record.projectName}`);
  };

  //enviando os dados  pré-existentes em edição
  const SaveEditedPartNumber = async (payloadEditedData: any) => {
    try {
      await api.put(`/components/packaging/prices`, payloadEditedData);
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnSave'));
    }
  };

  //enviando os dados adicionados na edição
  const SaveNewPartNumberData = async (payloadNewData: any) => {
    try {
      payloadNewData.length > 0 && api.post(`/components/packaging/price`, payloadNewData);
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnSave'));
    }
  };

  const handleSendAllData = (payloadEditedData: any, payloadNewData: any) => {
    setIsLoading(true);
    Promise.all([SaveEditedPartNumber(payloadEditedData), SaveNewPartNumberData(payloadNewData)])
      .then(() => {
        openAlert('success', t('toast.successOnSave'));
        handleFetchPackingPrices();
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  //gerando uma data para o novo registro através da referência de algums dos meses existentes
  const CreateMonthForNewDataToSend = (record: PackingPriceItem, monthSequence: number): string => {
    if (record.monthPrice1) {
      const data = dayjs(record.monthPrice1.month, { format: 'MMMM/YYYY' });
      const newDate = data.add(monthSequence - 1, 'month');
      return newDate.format('MMMM');
    }

    if (record.monthPrice2) {
      const data = dayjs(record.monthPrice2.month, { format: 'MMMM/YYYY' });
      if (monthSequence === 1) {
        const newDate = data.subtract(monthSequence, 'month');
        return newDate.format('MMMM');
      }
      if (monthSequence === 3) {
        const newDate = data.add(1, 'month');
        return newDate.format('MMMM');
      }
    }

    if (record.monthPrice3) {
      const data = dayjs(record.monthPrice3.month, { format: 'MMMM/YYYY' });
      if (monthSequence === 2) {
        const newDate = data.subtract(1, 'month');
        return newDate.format('MMMM');
      }
      if (monthSequence === 1) {
        const newDate = data.subtract(2, 'month');
        return newDate.format('MMMM');
      }
    }

    return '';
  };

  //gerando os payloads para edição/criação de dados
  const GeneratePayloadsToEditData = (record: PackingPriceItem) => {
    try {
      setIsLoading(true);
      const valueForm = form.getFieldsValue();
      const payloadToEdit = [];
      record.monthPrice1 &&
        payloadToEdit.push({
          id: record.monthPrice1.id,
          gross: valueForm.monthPrice1.gross || record.monthPrice1.gross,
          netPrices: valueForm.monthPrice1.netPrices || record.monthPrice1.netPrices,
          logo: valueForm.logo || record.logo,
        });

      record.monthPrice2 &&
        payloadToEdit.push({
          id: record.monthPrice2.id,
          gross: valueForm.monthPrice2.gross || record.monthPrice2.gross,
          netPrices: valueForm.monthPrice2.netPrices || record.monthPrice2.netPrices,
          logo: valueForm.logo || record.logo,
        });

      record.monthPrice3 &&
        payloadToEdit.push({
          id: record.monthPrice3.id,
          gross: valueForm.monthPrice3.gross || record.monthPrice3.gross,
          netPrices: valueForm.monthPrice3.netPrices || record.monthPrice3.netPrices,
          logo: valueForm.logo || record.logo,
        });

      const payloadToSave = [];

      !record.monthPrice1 &&
        (valueForm.monthPrice1.gross || valueForm.monthPrice1.netPrices) &&
        payloadToSave.push({
          gross: valueForm.monthPrice1.gross,
          netPrices: valueForm.monthPrice1.netPrices,
          partNumber: record.partNumber,
          monthName: CreateMonthForNewDataToSend(record, 1),
          projectName: record.projectName,
          year: record.year,
          cm: record.cm,
        });

      !record.monthPrice2 &&
        (valueForm.monthPrice2.gross || valueForm.monthPrice2.netPrices) &&
        payloadToSave.push({
          gross: valueForm.monthPrice2.gross,
          netPrices: valueForm.monthPrice2.netPrices,
          partNumber: record.partNumber,
          monthName: CreateMonthForNewDataToSend(record, 2),
          projectName: record.projectName,
          year: record.year,
          cm: record.cm,
        });

      !record.monthPrice3 &&
        (valueForm.monthPrice3.gross || valueForm.monthPrice3.netPrices) &&
        payloadToSave.push({
          gross: valueForm.monthPrice3.gross,
          netPrices: valueForm.monthPrice3.netPrices,
          partNumber: record.partNumber,
          monthName: CreateMonthForNewDataToSend(record, 3),
          projectName: record.projectName,
          year: record.year,
          cm: record.cm,
        });

      handleSendAllData(payloadToEdit, payloadToSave);
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnSave'));
    } finally {
      setKeyToEditRecord('');
      setIsLoading(false);
    }
  };

  const isEditing = (record: PackingPriceItem) =>
    `${record.partNumber}-${record.projectName}` === keyToEditRecord;

  const handleValidateFieldsBeforeSendData = () => {
    try {
      const fields = form.getFieldsError();
      let validate = true;
      fields.forEach((item) => {
        if (item.errors.length > 0) {
          validate = false;
        }
      });
      return validate;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const handleRoundsToTwoDecimalPlaces = (value: string): string => {
    try {
      if (value) {
        const valueAsNumber = Number(value);
        if (Number.isNaN(valueAsNumber)) {
          return '';
        }
        return valueAsNumber.toLocaleString(t('common.localeMoney'), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }
      return '';
    } catch (error) {
      return '';
    }
  };

  const columns: any = [
    // part number
    {
      title: () => {
        return (
          <S.PartNumberHeader>
            <span>{t('pages.packingPrices.tableHeaders.PartNumber')}</span>
          </S.PartNumberHeader>
        );
      },
      dataIndex: 'partNumber',
      key: 'partNumber',
      align: 'center',
      editable: false,
      render: (_: string, record: PackingPriceItem) => {
        return <S.RowCell>{record.isTotalizer ? '' : record.partNumber}</S.RowCell>;
      },
    },
    //description
    {
      title: () => {
        return (
          <S.DescriptionHeader>
            <span>{t('pages.packingPrices.tableHeaders.description')}</span>
          </S.DescriptionHeader>
        );
      },
      dataIndex: 'description',
      key: 'description',
      align: 'left',

      render: (_: string, record: PackingPriceItem) => {
        return <S.RowCell>{record.description}</S.RowCell>;
      },
    },
    //logo
    {
      title: () => {
        return (
          <S.LogoHeader>
            <span>{t('pages.packingPrices.tableHeaders.logo')}</span>
          </S.LogoHeader>
        );
      },
      dataIndex: 'logo',
      key: 'logo',
      align: 'left',
      width: '5rem',
      render: (_: string, record: PackingPriceItem) => {
        return <S.RowCell>{record.isTotalizer ? '' : record.logo || '-'}</S.RowCell>;
      },
      onCell: (record: any) => ({
        record,
        dataIndex: 'logo',
        editing: isEditing(record),
      }),
    },
    //QTY
    {
      title: () => {
        return (
          <S.QtyPerHeader>
            <span>{t('pages.packingPrices.tableHeaders.qtyPer')}</span>
          </S.QtyPerHeader>
        );
      },
      dataIndex: 'qtyPer',
      key: 'qtyPer',
      align: 'center',
      width: '5rem',
      className: 'remove-tip',
      render: (_: string, record: PackingPriceItem) => {
        return <S.RowCell>{record.isTotalizer ? '' : record.qtyPer}</S.RowCell>;
      },
    },

    //month 1
    {
      title: () => {
        return (
          <S.MonthHeader>
            <span>{monthTitleColum1}</span>
          </S.MonthHeader>
        );
      },

      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.packingPrices.tableHeaders.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['monthPrice1', 'gross'],
          key: 'gross',
          align: 'right',
          render: (_: string, record: PackingPriceItem) => {
            return (
              <S.RowCellNetCoast>
                {record.monthPrice1?.gross ? `R$${record.monthPrice1?.gross}` : '-'}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: PackingPriceItem) => ({
            record,
            inputType: 'money',
            dataIndex: ['monthPrice1', 'gross'],
            editing: isEditing(record),
            maxWidth: '4rem',
            required: true,
            // title: `${t('pages.packingPrices.tableHeaders.gross')}`,
            messageRequired: `${t('common.mandatoryField')}`,
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.packingPrices.tableHeaders.netPrice')}</span>
                {/* <Tooltip placement="right" title="Calculated by the system">
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip> */}
              </S.NetCell>
            );
          },
          dataIndex: ['monthPrice1', 'netPrices'],
          key: 'netPrices',
          align: 'right',
          render: (_: string, record: PackingPriceItem) => {
            if (record.isTotalizer) {
              return (
                <S.ContainerTotalizer>
                  <S.ContainerValues type="BRL">
                    <p>
                      <span>BRL: </span>
                      {`R$${record.monthPrice1?.netPrices}`}
                    </p>
                  </S.ContainerValues>
                  <Tooltip placement="top" title={record.monthPrice1.originalExrate}>
                    <S.ContainerValues type="DOLAR">
                      <p>
                        <span>USD: </span>
                        {`$${record.monthPrice1?.totalExRate}`}
                      </p>
                    </S.ContainerValues>
                  </Tooltip>
                </S.ContainerTotalizer>
              );
            }

            return (
              <S.RowCellNetCoast>
                {record.monthPrice1?.netPrices ? `R$${record.monthPrice1?.netPrices}` : '-'}
              </S.RowCellNetCoast>
            );

            // return (
            //   <S.RowCellNetCoast>
            //     {record.monthPrice1?.netPrices ? (
            //       record.monthPrice1.isCalculated ? (
            //         <S.NetCostContainer
            //           onClick={() => {
            //             setModalTaxParams({ componentType: 'PACKING', spec: record.spec || '' });
            //             openModalTax();
            //           }}
            //         >
            //           R${record.monthPrice1?.netPrices}{' '}
            //         </S.NetCostContainer>
            //       ) : (
            //         `R$${record.monthPrice1?.netPrices}`
            //       )
            //     ) : (
            //       '-'
            //     )}
            //   </S.RowCellNetCoast>
            // );
          },
          onCell: (record: any) => ({
            record,
            maxWidth: '4rem',
            inputType: 'money',
            required: true,
            title: `${t('pages.packingPrices.tableHeaders.netPrice')}`,
            messageRequired: `${t('common.mandatoryField')}`,
            dataIndex: ['monthPrice1', 'netPrices'],
            editing: isEditing(record),
          }),
        },
      ],
    },

    //month 2
    {
      title: () => {
        return (
          <S.MonthHeader>
            <span>{monthTitleColum2}</span>
          </S.MonthHeader>
        );
      },
      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.packingPrices.tableHeaders.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['monthPrice2', 'gross'],
          key: 'gross',
          align: 'right',
          render: (_: string, record: PackingPriceItem) => {
            return (
              <S.RowCellNetCoast>
                {record.monthPrice2?.gross ? `R$${record.monthPrice2?.gross}` : '-'}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: any) => ({
            record,
            maxWidth: '4rem',
            inputType: 'money',
            required: true,
            title: `${t('pages.packingPrices.tableHeaders.gross')}`,
            messageRequired: `${t('common.mandatoryField')}`,
            dataIndex: ['monthPrice2', 'gross'],
            editing: isEditing(record),
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.packingPrices.tableHeaders.netPrice')}</span>
                {/* <Tooltip placement="right" title="Calculated by the system">
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip> */}
              </S.NetCell>
            );
          },
          dataIndex: ['monthPrice2', 'netPrices'],
          key: 'netPrices',
          align: 'right',
          render: (_: string, record: PackingPriceItem) => {
            if (record.isTotalizer) {
              return (
                <S.ContainerTotalizer>
                  <S.ContainerValues type="BRL">
                    <p>
                      <span>BRL: </span>
                      {`R$${record.monthPrice2?.netPrices}`}
                    </p>
                  </S.ContainerValues>
                  <Tooltip placement="top" title={record.monthPrice2.originalExrate}>
                    <S.ContainerValues type="DOLAR">
                      <p>
                        <span>USD: </span>
                        {`$${record.monthPrice2?.totalExRate}`}
                      </p>
                    </S.ContainerValues>
                  </Tooltip>
                </S.ContainerTotalizer>
              );
            }

            return (
              <S.RowCellNetCoast>
                {record.monthPrice2?.netPrices ? `R$${record.monthPrice2?.netPrices}` : '-'}
              </S.RowCellNetCoast>
            );

            // return (
            //   <S.RowCellNetCoast>
            //     {record.monthPrice2?.netPrices ? (
            //       record.monthPrice2.isCalculated ? (
            //         <S.NetCostContainer
            //           onClick={() => {
            //             setModalTaxParams({ componentType: 'PACKING', spec: record.spec || '' });
            //             openModalTax();
            //           }}
            //         >
            //           R${record.monthPrice2?.netPrices}
            //         </S.NetCostContainer>
            //       ) : (
            //         `R$${record.monthPrice2?.netPrices}`
            //       )
            //     ) : (
            //       '-'
            //     )}
            //   </S.RowCellNetCoast>
            // );
          },
          onCell: (record: PackingPriceItem) => ({
            record,
            inputType: 'money',
            maxWidth: '4rem',
            required: true,
            title: `${t('pages.packingPrices.tableHeaders.netPrice')}`,
            messageRequired: `${t('common.mandatoryField')}`,
            dataIndex: ['monthPrice2', 'netPrices'],
            editing: isEditing(record),
          }),
        },
      ],
    },
    //month 3
    {
      title: () => {
        return (
          <S.MonthHeader lastMonth>
            <span>{monthTitleColum3}</span>
          </S.MonthHeader>
        );
      },
      children: [
        {
          title: () => {
            return (
              <S.GrossNetCell>
                <span>{t('pages.packingPrices.tableHeaders.gross')}</span>
              </S.GrossNetCell>
            );
          },
          dataIndex: ['monthPrice3', 'gross'],
          key: 'gross',
          align: 'right',
          render: (_: string, record: PackingPriceItem) => {
            return (
              <S.RowCellNetCoast>
                {record.monthPrice3?.gross ? `R$${record.monthPrice3?.gross}` : '-'}
              </S.RowCellNetCoast>
            );
          },
          onCell: (record: PackingPriceItem) => ({
            record,
            maxWidth: '4rem',
            inputType: 'money',
            required: true,
            title: `${t('pages.packingPrices.tableHeaders.gross')}`,
            messageRequired: `${t('common.mandatoryField')}`,
            dataIndex: ['monthPrice3', 'gross'],
            editing: isEditing(record),
          }),
        },
        {
          title: () => {
            return (
              <S.NetCell>
                <span>{t('pages.packingPrices.tableHeaders.netPrice')}</span>
                {/* <Tooltip placement="right" title="Calculated by the system">
                  <img src={calculatedIcon} alt="calculated" />
                </Tooltip> */}
              </S.NetCell>
            );
          },
          dataIndex: ['monthPrice3', 'netPrices'],
          key: 'netPrices',
          align: 'right',
          render: (_: string, record: PackingPriceItem) => {
            if (record.isTotalizer) {
              return (
                <S.ContainerTotalizer>
                  <S.ContainerValues type="BRL">
                    <p>
                      <span>BRL: </span>
                      {`R$${record.monthPrice3?.netPrices}`}
                    </p>
                  </S.ContainerValues>
                  <Tooltip placement="top" title={record.monthPrice3.originalExrate}>
                    <S.ContainerValues type="DOLAR">
                      <p>
                        <span>USD: </span>
                        {`$${record.monthPrice3?.totalExRate}`}
                      </p>
                    </S.ContainerValues>
                  </Tooltip>
                </S.ContainerTotalizer>
              );
            }

            return (
              <S.RowCellNetCoast>
                {record.monthPrice3?.netPrices ? `R$${record.monthPrice3?.netPrices}` : '-'}
              </S.RowCellNetCoast>
            );

            // return (
            //   <S.RowCellNetCoast>
            //     {record.monthPrice3?.netPrices ? (
            //       record.monthPrice3.isCalculated ? (
            //         <S.NetCostContainer
            //           onClick={() => {
            //             setModalTaxParams({ componentType: 'PACKING', spec: record.spec || '' });
            //             openModalTax();
            //           }}
            //         >
            //           R${record.monthPrice3?.netPrices}{' '}
            //         </S.NetCostContainer>
            //       ) : (
            //         `R$${record.monthPrice3?.netPrices}`
            //       )
            //     ) : (
            //       '-'
            //     )}
            //   </S.RowCellNetCoast>
            // );
          },
          onCell: (record: PackingPriceItem) => ({
            record,
            maxWidth: '4rem',
            inputType: 'money',
            required: true,
            title: `${t('pages.packingPrices.tableHeaders.netPrice')}`,
            messageRequired: `${t('common.mandatoryField')}`,
            dataIndex: ['monthPrice3', 'netPrices'],
            editing: isEditing(record),
          }),
        },
      ],
    },
    //actions
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 40,
      className: 'remove-tip',
      render: (_: any, record: PackingPriceItem) => {
        const editable = isEditing(record);
        const actions = [
          {
            key: '1',
            label: (
              <S.EditButton
                icon={<EditOutlined />}
                type="link"
                onClick={() => {
                  prepareToEdit(record);
                }}
                data-cy="edit"
              >
                {t('common.edit')}
              </S.EditButton>
            ),
          },
        ];

        return editable ? (
          <Space direction="horizontal" size={12}>
            <Button
              type="primary"
              style={{ borderRadius: '8px' }}
              onClick={() => {
                if (handleValidateFieldsBeforeSendData()) {
                  GeneratePayloadsToEditData(record);
                }
              }}
              data-cy="save"
            >
              {t('common.save')}
            </Button>
            <Popconfirm
              title={t('common.cancelMessage')}
              onConfirm={() => {
                setKeyToEditRecord('');
              }}
              onClick={() => {
                setKeyToEditRecord('');
              }}
            >
              <Button shape="circle" icon={<AiOutlineClose />} data-cy="cancel" />
            </Popconfirm>
          </Space>
        ) : record.isTotalizer ? null : (
          <TableAction dropdownItems={actions} placement="bottom" />
        );
      },
    },
  ];

  const handleCalculateValueNetPrice = (qty: number, netPrice: string): number => {
    try {
      const numberNetPrice = Number(netPrice);
      if (Number.isNaN(numberNetPrice) || !netPrice) return 0;
      if (qty <= 0) return numberNetPrice;

      return qty * numberNetPrice;
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const handleAddTotalizerNetCost = (project: ProjectPackingItem): Array<PackingPriceItem> => {
    try {
      const totalizers: ItemTotalizer = {
        totalNetMonth1: 0,
        totalNetMonth2: 0,
        totalNetMonth3: 0,
        fxRateMonth1: '0',
        fxRateMonth2: '0',
        fxRateMonth3: '0',
        totalUsd1: 0,
        totalUsd2: 0,
        totalUsd3: 0,
      };

      // const { indexMonth1, indexMonth2, indexMonth3 } =
      //   handleFindValueOffDollarQuotationsByProject(project);

      // totalizers.fxRateMonth1 =
      //   indexMonth1 === VALUE_FX_RATE_NOT_FOUND
      //     ? t('pages.packingPrices.tooltips.na')
      //     : `${t('pages.packingPrices.tooltips.exRate')} -  ${
      //         project.components[indexMonth1].monthPrice1?.totalExRate
      //       } `;
      const fxRateMonth1 = project.components.find(
        (component) => component.monthPrice1?.totalExRate
      )?.monthPrice1.totalExRate;
      const fxRateMonth2 = project.components.find(
        (component) => component.monthPrice2?.totalExRate
      )?.monthPrice2.totalExRate;
      const fxRateMonth3 = project.components.find(
        (component) => component.monthPrice3?.totalExRate
      )?.monthPrice3.totalExRate;

      totalizers.fxRateMonth1 = fxRateMonth1
        ? `${t('pages.packingPrices.tooltips.exRate')} -  ${fxRateMonth1} `
        : t('pages.packingPrices.tooltips.na');

      totalizers.fxRateMonth2 = fxRateMonth2
        ? `${t('pages.packingPrices.tooltips.exRate')} -  ${fxRateMonth2} `
        : t('pages.packingPrices.tooltips.na');

      totalizers.fxRateMonth3 = fxRateMonth3
        ? `${t('pages.packingPrices.tooltips.exRate')} -  ${fxRateMonth3} `
        : t('pages.packingPrices.tooltips.na');

      project.components.forEach((component: PackingPriceItem) => {
        const calculatedValueNetMonth1 = handleCalculateValueNetPrice(
          component.qtyPer,
          component.monthPrice1?.netPrices
        );
        const calculatedValueNetMonth2 = handleCalculateValueNetPrice(
          component.qtyPer,
          component.monthPrice2?.netPrices
        );
        const calculatedValueNetMonth3 = handleCalculateValueNetPrice(
          component.qtyPer,
          component.monthPrice3?.netPrices
        );

        totalizers.totalNetMonth1 += calculatedValueNetMonth1;
        totalizers.totalNetMonth2 += calculatedValueNetMonth2;
        totalizers.totalNetMonth3 += calculatedValueNetMonth3;
        totalizers.totalUsd1 +=
          calculatedValueNetMonth1 / Number(component.monthPrice1?.totalExRate) || 0;
        totalizers.totalUsd2 +=
          calculatedValueNetMonth2 / Number(component.monthPrice2?.totalExRate) || 0;
        totalizers.totalUsd3 +=
          calculatedValueNetMonth3 / Number(component.monthPrice3?.totalExRate) || 0;
      });

      const arrayWithTotalizers = [...project.components];
      arrayWithTotalizers.push({
        cm: '',
        description: '',
        id: '',
        logo: '',
        spec: '',
        partNumber: '',
        qtyPer: 0,
        source: 'FOB',
        monthPrice1: {
          gross: '',
          id: '',
          month: '',
          netPrices: String(totalizers.totalNetMonth1.toFixed(2)),
          taxes: '',
          totalExRate: String(totalizers.totalUsd1.toFixed(2)),
          originalExrate: totalizers.fxRateMonth1,
        },
        monthPrice2: {
          gross: '',
          id: '',
          month: '',
          netPrices: String(totalizers.totalNetMonth2.toFixed(2)),
          taxes: '',
          totalExRate: totalizers.totalUsd2.toFixed(2),
          originalExrate: totalizers.fxRateMonth2,
        },
        monthPrice3: {
          gross: '',
          id: '',
          month: '',
          netPrices: String(totalizers.totalNetMonth3.toFixed(2)),
          taxes: '',
          totalExRate: String(totalizers.totalUsd3.toFixed(2)),
          originalExrate: totalizers.fxRateMonth3,
        },
        projectName: '',
        year: 0,
        isTotalizer: true,
      });

      return arrayWithTotalizers.map((component) => {
        return {
          ...component,
          monthPrice1: component.monthPrice1
            ? {
                gross: handleRoundsToTwoDecimalPlaces(component.monthPrice1?.gross),
                id: component.monthPrice1.id,
                month: component.monthPrice1.month,
                netPrices: handleRoundsToTwoDecimalPlaces(component.monthPrice1.netPrices),
                taxes: component.monthPrice1.taxes,
                totalExRate: component.monthPrice1.totalExRate,
                originalExrate: component.monthPrice1.originalExrate,
                isCalculated: component.monthPrice1.isCalculated,
              }
            : component.monthPrice1,
          monthPrice2: component.monthPrice2
            ? {
                gross: handleRoundsToTwoDecimalPlaces(component.monthPrice2.gross),
                id: component.monthPrice2.id,
                month: component.monthPrice2.month,
                netPrices: handleRoundsToTwoDecimalPlaces(component.monthPrice2.netPrices),
                taxes: component.monthPrice2.taxes,
                totalExRate: component.monthPrice2.totalExRate,
                originalExrate: component.monthPrice2.originalExrate,
                isCalculated: component.monthPrice2.isCalculated,
              }
            : component.monthPrice2,
          monthPrice3: component.monthPrice3
            ? {
                gross: handleRoundsToTwoDecimalPlaces(component.monthPrice3.gross),
                id: component.monthPrice3.id,
                month: component.monthPrice3.month,
                netPrices: handleRoundsToTwoDecimalPlaces(component.monthPrice3.netPrices),
                taxes: component.monthPrice3.taxes,
                totalExRate: component.monthPrice3.totalExRate,
                originalExrate: component.monthPrice3.originalExrate,
                isCalculated: component.monthPrice3.isCalculated,
              }
            : component.monthPrice3,
        };
      });
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const RenderTablesComponents = () => {
    return packingPriceComponents.map((item: ProjectPackingItem) => {
      return (
        <S.TablesContainer key={item.projectName}>
          <S.HeaderExpandableItem>
            <ExpandableTable
              isOpen={expandableViewStatus[item.projectName]}
              onClick={() => {
                handleUpdateExpandableViews(item.projectName);
              }}
            />
            <S.HeaderExpandableContainer>
              <div>
                <span>{item.projectName}</span>
              </div>
            </S.HeaderExpandableContainer>
          </S.HeaderExpandableItem>
          {expandableViewStatus[item.projectName] && (
            <Form form={form} component={false}>
              <S.TableContent>
                <SimpleTable
                  striped
                  bordered={false}
                  pagination={false}
                  dataSource={handleAddTotalizerNetCost(item)}
                  rowKey="partNumber"
                  columns={columns}
                  components={{
                    body: {
                      cell: EditableTableCell,
                    },
                  }}
                  scroll={{ x: 'max-content', y: 'auto' }}
                  headerNoPadding
                  noBorder
                  scrollable
                />
              </S.TableContent>
            </Form>
          )}
        </S.TablesContainer>
      );
    });
  };

  return {
    params,
    // selectPartNumber,
    selectSpec,
    partOptions,
    CMs,
    projects,
    specifications,
    showError,
    isLoading,
    dollarQuotation,
    monthImportedReferente,
    packingPriceComponents,
    visibleModalTax,
    modalTaxParams,
    closeModalTax,
    handleFetchPackingPrices,
    setShowError,
    setPartOptions,
    handleUpdateFilters,
    setSelectSpec,
    handleRenderClearAllFilters,
    handleRenderFiltersTags,
    RenderTablesComponents,
    handleFetchFiltersData,
  };
}
