import { Form, Table, Tooltip, Transfer, type TableColumnsType, type TransferProps } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { TransferItem } from 'antd/es/transfer';
import { AxiosResponse } from 'axios';
import { Key, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledSelectOptions } from '../../../../components/Common/StyledSelect/types';
import StyledIndicationTag from '../../../../components/IndicationTag';
import { useAlert } from '../../../../context/AlertContext';
import { formatDataToSelect } from '../../../../helpers/formatDataToSelect';
import { getURI } from '../../../../helpers/utils';
import { api } from '../../../../services/api';
import { services } from '../../../../services/services';
import { IError, IStep2Form } from '../../types';
import {
  IEditPartNumbers,
  IResponsePartNumbers,
  IResponseProjects,
  ITransferPartNumber,
  ITransferProjet,
  TableTransferProps,
} from './types';

export function useRegisterComponents(bidId: string) {
  const { openAlert } = useAlert();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);
  const [components, setComponents] = useState<IStep2Form[]>([]);
  const [form] = Form.useForm<IStep2Form>();

  const [categories, setCategories] = useState<StyledSelectOptions[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>();
  const [specs, setSpecs] = useState<StyledSelectOptions[]>();
  const [selectedSpecs, setSelectedSpecs] = useState<string[] | null>(null);
  const [projects, setProjects] = useState<ITransferProjet[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<ITransferProjet[]>([]);
  const [targetProjects, setTargetProjects] =
    useState<TransferProps<ITransferProjet>['targetKeys']>();
  const [partNumbers, setPartNumbers] = useState<ITransferPartNumber[]>([]);
  const [selectedPartNumbers, setSelectedPartNumbers] = useState<ITransferPartNumber[]>([]);
  const [targetPartNumbers, setTargetPartNumbers] =
    useState<TransferProps<ITransferPartNumber>['targetKeys']>();

  const [componentIdOpen, setComponentIdOpen] = useState<number | null>(null);
  const [isNewComponentOpen, setIsNewComponentOpen] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<{
    isModalOpen: boolean;
    componentId?: number;
  }>({
    isModalOpen: false,
  });
  const [isLoadingData, setIsLoadingData] = useState<{
    loadingComponents: boolean;
    loadingCategories: boolean;
    loadingSpecs: boolean;
    loadingProjects: boolean;
    loadingPartNumber: boolean;
  }>({
    loadingComponents: false,
    loadingCategories: false,
    loadingSpecs: false,
    loadingPartNumber: false,
    loadingProjects: false,
  });

  const fetchComponents = async () => {
    try {
      setIsLoadingData({ ...isLoadingData, loadingComponents: true });
      const { data } = await api.get(`${services.rfq}/bid/registerComponents/${bidId}`);
      if (data.step2) {
        setComponents(data.step2.step2Components);
      }
    } catch (err) {
      const error = err as IError;
      openAlert('error', error.errorResponse?.messageDetail || t('toast.errorOnList'));
    } finally {
      setIsLoadingData({ ...isLoadingData, loadingComponents: false });
    }
  };

  const saveComponent = async (
    values: IStep2Form
  ): Promise<AxiosResponse<IStep2Form> | undefined> => {
    try {
      setIsLoadingSubmit(true);
      if (values.stepComponentId) {
        const response = await api.put(
          `${services.rfq}/bid/registerComponents/${bidId}/stepComponent/${values.stepComponentId}`,
          { ...values }
        );
        return response;
      }
      const response = await api.post(`${services.rfq}/bid/registerComponents/${bidId}`, {
        ...values,
      });
      form.resetFields();
      return response;
    } catch (err) {
      const error = err as IError;
      openAlert('error', error.errorResponse.messageDetail || t('toast.errorOnSave'));
    } finally {
      setIsLoadingSubmit(false);
    }
  };

  const fetchCategories = async () => {
    try {
      setIsLoadingData({ ...isLoadingData, loadingCategories: true });
      const { data, status } = await api.get('/avlc/filter?filterToReturn=category');
      if (status === 200) {
        setCategories(formatDataToSelect(data, false));
      } else if (status === 204) {
        setCategories([]);
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
    } finally {
      setIsLoadingData({ ...isLoadingData, loadingCategories: false });
    }
  };

  const fetchSpecs = async () => {
    try {
      setIsLoadingData({ ...isLoadingData, loadingSpecs: true });
      setSpecs([]);
      const { data, status } = await api.get(
        getURI('/avlc/filter', {
          filterToReturn: 'specification',
          category: selectedCategory,
        })
      );
      if (status === 200) {
        setSpecs(formatDataToSelect(data, false));
      } else if (status === 204) {
        setSpecs([]);
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
    } finally {
      setIsLoadingData({ ...isLoadingData, loadingSpecs: true });
    }
  };

  const formatProjectToTransfer = (data: IResponseProjects) => {
    const formattedProjects = data.projects.map((project) => {
      return {
        key: project.project,
        project: project.project,
        cm: project.cm,
      };
    });

    return formattedProjects;
  };

  const handleSelectedSpecs = (SelectedProjects: ITransferProjet[]) => {
    if (selectedSpecs && selectedSpecs[0].length > 0) {
      const projectsKeys = SelectedProjects.map((project) => project.key);
      const transferredItems = selectedProjects.filter((item) => projectsKeys.includes(item.key));
      setSelectedProjects(transferredItems);
      setTargetProjects(transferredItems.map((item) => item.key));

      if (selectedSpecs) {
        const newSelectedPartNumber = selectedPartNumbers.filter((item) => {
          return selectedSpecs.includes(item.specification);
        });

        setSelectedPartNumbers(newSelectedPartNumber);
        setTargetPartNumbers(newSelectedPartNumber.map((item) => item.key));
      }
    } else {
      setSelectedProjects([]);
      setTargetProjects([]);
      setSelectedPartNumbers([]);
      setTargetPartNumbers([]);
    }
  };

  const fetchProjects = async () => {
    try {
      setIsLoadingData({ ...isLoadingData, loadingProjects: true, loadingPartNumber: false });
      const { data, status } = await api.get<IResponseProjects>(
        getURI('/avlc/bid/filter', {
          category: selectedCategory,
          specifications: selectedSpecs,
          filterToReturn: 'projectName',
        })
      );
      if (status === 200) {
        const formattedProjests = formatProjectToTransfer(data);
        setProjects(formattedProjests);
        if (selectedProjects.length > 0) handleSelectedSpecs(formattedProjests);
      } else if (status === 204) {
        setProjects([]);
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
    } finally {
      setIsLoadingData({ ...isLoadingData, loadingProjects: false, loadingPartNumber: false });
    }
  };

  const formatPartNumbersToTransfer = (data: IEditPartNumbers) => {
    const formattedPartNumbers = data.partNumbers.map((partNumber) => {
      return {
        key: `${partNumber.partNumber}${partNumber.project}${partNumber.qstatus}${partNumber.specification}`,
        partNumber: partNumber.partNumber,
        supplier: partNumber.supplier,
        qstatus: partNumber.qstatus,
        specification: partNumber.specification,
        project: partNumber.project,
      };
    });

    return formattedPartNumbers;
  };

  const fetchPartNumbers = async () => {
    try {
      setIsLoadingData({ ...isLoadingData, loadingProjects: false, loadingPartNumber: true });
      const { data, status } = await api.get<IResponsePartNumbers>(
        getURI('/avlc/bid/filter', {
          filterToReturn: 'partNumber',
          category: selectedCategory,
          specifications: selectedSpecs,
          projects: selectedProjects.map((project) => project.key).join(', '),
        })
      );
      if (status === 200) {
        const renamedSpec = data.partNumbers.map((partNumber) => {
          return {
            ...partNumber,
            specification: partNumber.spec,
          };
        });

        setPartNumbers(formatPartNumbersToTransfer({ partNumbers: renamedSpec }));
      } else if (status === 204) {
        setPartNumbers([]);
      }
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
    } finally {
      setIsLoadingData({ ...isLoadingData, loadingProjects: false, loadingPartNumber: false });
    }
  };

  const deleteComponent = async (componentId: number) => {
    try {
      setIsLoading(true);
      const { status } = await api.delete(
        `${services.rfq}/bid/registerComponents/${bidId}/stepComponent/${componentId}`,
        {
          data: {
            id: bidId,
            stepComponentId: componentId,
          },
        }
      );
      if (status === 204) {
        fetchComponents();
      }
    } catch (err) {
      const error = err as IError;
      openAlert('error', error.errorResponse.messageDetail || t('toast.errorOnDelete'));
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeProject: TransferProps<ITransferProjet>['onChange'] = (
    nextTargetKeys,
    direction,
    moveKeys
  ) => {
    setTargetProjects(nextTargetKeys);
    if (direction === 'right') {
      const transferredItems = projects.filter((item) => moveKeys.includes(item.key));
      setSelectedProjects((prev) => [...prev, ...transferredItems]);
    } else {
      const newSelectedPartNumber = selectedPartNumbers.filter((item) => {
        return !moveKeys.includes(item.project);
      });

      setSelectedPartNumbers(newSelectedPartNumber);

      setTargetPartNumbers(newSelectedPartNumber.map((item) => item.key));

      setSelectedProjects((prevData) => prevData.filter((item) => !moveKeys.includes(item.key)));
    }
  };

  const onChangePartNumber: TransferProps<ITransferPartNumber>['onChange'] = (
    nextTargetKeys,
    direction,
    moveKeys
  ) => {
    setTargetPartNumbers(nextTargetKeys);
    if (direction === 'right') {
      const transferredItems = partNumbers.filter((item) => moveKeys.includes(item.key));
      setSelectedPartNumbers((prev) => [...prev, ...transferredItems]);
    } else {
      setSelectedPartNumbers((prevData) => prevData.filter((item) => !moveKeys.includes(item.key)));
    }
  };

  function TableTransfer<T extends TransferItem>({
    leftColumns,
    rightColumns,
    ...restProps
  }: TableTransferProps<T>) {
    return (
      <Transfer {...restProps}>
        {({
          direction,
          filteredItems,
          onItemSelect,
          onItemSelectAll,
          selectedKeys: listSelectedKeys,
        }) => {
          const columns = direction === 'left' ? leftColumns : rightColumns;

          const rowSelection: TableRowSelection<T> = {
            onChange(selectedRowKeys: Key[]) {
              onItemSelectAll(selectedRowKeys as string[], 'replace');
            },
            selectedRowKeys: listSelectedKeys,
            selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT, Table.SELECTION_NONE],
          };

          return (
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={filteredItems}
              pagination={false}
              style={{ maxHeight: '26rem', minHeight: 'max-content', overflowY: 'scroll' }}
              size="small"
              onRow={({ key }) => ({
                onClick: () => {
                  key && onItemSelect(key, !listSelectedKeys.includes(key));
                },
              })}
            />
          );
        }}
      </Transfer>
    );
  }

  const formatSelectedTargetProjects = (data: IResponseProjects) => {
    setTargetProjects(
      data.projects.map((project) => {
        return project.project;
      })
    );
  };

  const formatSelectedTargetPartNumbers = (data: IEditPartNumbers) => {
    setTargetPartNumbers(
      data.partNumbers.map((partNumber) => {
        return `${partNumber.partNumber}${partNumber.project}${partNumber.qstatus}${partNumber.specification}`;
      })
    );
  };

  const onEditComponent = (values: IStep2Form) => {
    setSelectedCategory(values.category);
    setSelectedSpecs(values.specifications);
    setSelectedProjects(formatProjectToTransfer({ projects: values.projects }));
    formatSelectedTargetProjects({ projects: values.projects });
    setSelectedPartNumbers(formatPartNumbersToTransfer({ partNumbers: values.partNumbers }));
    formatSelectedTargetPartNumbers({ partNumbers: values.partNumbers });
  };

  const projectsColumns: TableColumnsType<ITransferProjet> = [
    {
      dataIndex: 'project',
      title: t('pages.newRfq.steps.registerComponents.fields.project'),
    },
    {
      dataIndex: 'cm',
      title: t('pages.newRfq.steps.registerComponents.fields.cm'),
    },
  ];

  const partNumbersColumns: TableColumnsType<ITransferPartNumber> = [
    {
      dataIndex: 'partNumber',
      title: t('pages.newRfq.steps.registerComponents.fields.parNumber'),
      render: (_, record) => {
        return <Tooltip title={record.specification}>{record.partNumber}</Tooltip>;
      },
    },
    {
      dataIndex: 'project',
      title: t('pages.newRfq.steps.registerComponents.fields.project'),
    },
    {
      dataIndex: 'qstatus',
      title: t('pages.newRfq.steps.registerComponents.fields.qStatus'),
      align: 'center',
      render: (_, record) => {
        return <StyledIndicationTag text={record.qstatus} />;
      },
    },
    {
      dataIndex: 'supplier',
      title: t('pages.newRfq.steps.registerComponents.fields.supplier'),
    },
  ];

  useEffect(() => {
    if (bidId) {
      fetchComponents();
    }
  }, []);

  useEffect(() => {
    if ((isNewComponentOpen || componentIdOpen) && selectedProjects.length > 0)
      Promise.all([fetchPartNumbers()]);
    else setPartNumbers([]);
  }, [selectedProjects]);

  useEffect(() => {
    if ((isNewComponentOpen || componentIdOpen) && selectedSpecs) Promise.all([fetchProjects()]);
  }, [selectedSpecs]);

  useEffect(() => {
    if ((isNewComponentOpen || componentIdOpen) && selectedCategory) {
      Promise.all([fetchSpecs()]);
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (isNewComponentOpen || componentIdOpen) Promise.all([fetchCategories()]);
  }, [componentIdOpen, isNewComponentOpen]);

  return {
    categories,
    selectedCategory,
    specs,
    selectedSpecs,
    projects,
    selectedProjects,
    targetProjects,
    partNumbersColumns,
    projectsColumns,
    partNumbers,
    targetPartNumbers,
    componentIdOpen,
    isNewComponentOpen,
    selectedPartNumbers,
    openDeleteModal,
    isLoading,
    form,
    components,
    isLoadingSubmit,
    isLoadingData,
    fetchCategories,
    fetchSpecs,
    fetchProjects,
    fetchPartNumbers,
    setSelectedCategory,
    setSelectedSpecs,
    onChangeProject,
    TableTransfer,
    onChangePartNumber,
    setComponentIdOpen,
    setIsNewComponentOpen,
    onEditComponent,
    setOpenDeleteModal,
    setTargetProjects,
    setSelectedProjects,
    setSelectedPartNumbers,
    setTargetPartNumbers,
    fetchComponents,
    deleteComponent,
    saveComponent,
  };
}
