import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { StyledHeader } from '../../components/Common/StyledHeaderPage';
import { StyledLoading } from '../../components/Common/StyledLoading';
import { ComponentSpecifications } from '../../components/Qualification/ComponentSpecifications';
import { QualificationStatus } from '../../components/Qualification/QualificationStatus';
import { Datasheet } from './components/Datasheet';
import { ODMDetails } from './components/ODMDetails';
import { QualificationRegisterInformations } from './components/QualificationRegisterInformations';
import { SampleTrackingNumber } from './components/SampleTrackingNumber';
import * as S from './styles';
import { useViewQualification } from './useViewQualification';

export function ViewQualification() {
  const { t } = useTranslation();
  const history = useHistory();
  const { qualificationData, isLoading, isLoadingStatus, steps } = useViewQualification();
  return (
    <S.Container>
      <S.BackButton
        type="link"
        icon={<ArrowLeftOutlined />}
        onClick={() => history.push('/qualification')}
      >
        {t('pages.viewQualification.backButton')}
      </S.BackButton>
      <StyledHeader title={t('pages.viewQualification.title')} />
      {isLoadingStatus ? <StyledLoading height={10} /> : <QualificationStatus data={steps} />}
      {isLoading ? (
        <StyledLoading height={10} />
      ) : (
        <>
          <QualificationRegisterInformations
            {...qualificationData.qualificationRegisterInformation}
          />
          <ComponentSpecifications viewAcer {...qualificationData?.componentSpecification} />
          <Datasheet data={qualificationData.dataSheet || []} />
          <SampleTrackingNumber data={qualificationData.sampleTrackingNumbers || []} />
          <ODMDetails data={qualificationData?.odmDetails} />
        </>
      )}
    </S.Container>
  );
}
