/* eslint-disable no-undefined */
import {
  FlagOutlined,
  FundViewOutlined,
  LockOutlined,
  TrophyOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import { RadioChangeEvent, TabsProps } from 'antd';
import { HttpStatusCode } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { useAlert } from '../../context/AlertContext';
import { BidStatus } from '../../helpers/enums';
import { getURI } from '../../helpers/utils';
import { api } from '../../services/api';
import { services } from '../../services/services';
import * as S from './styles';
import {
  IAltGroupDetails,
  IBidInformations,
  ICommentModal,
  IError,
  ISelectedAltGroup,
  IStepsStatus,
  ISupplierResponse,
  ITimeLine,
} from './types';

export function useViewRFQ() {
  const { openAlert } = useAlert();
  const { t } = useTranslation();
  const { search, pathname } = useLocation();
  const history = useHistory();
  const [bidId, setBidId] = useState<string>('');
  const [loading, setIsLoading] = useState<boolean>(false);
  const [isOpenCancelBidModal, setIsOpenCancelBidModal] = useState<boolean>(false);

  //overview
  const [bidInformations, setbidInformations] = useState<IBidInformations>();
  const initialSteps: IStepsStatus[] = [
    {
      title: <S.StatusTitle>{t('pages.viewRfq.steps.openBid')}</S.StatusTitle>,
      icon: <UnlockOutlined />,
      key: BidStatus.OPEN,
    },
    {
      title: <S.StatusTitle>{t('pages.viewRfq.steps.roundOpen')}</S.StatusTitle>,
      icon: <FlagOutlined />,
      key: BidStatus.ROUND_OPEN,
    },
    {
      title: <S.StatusTitle>{t('pages.viewRfq.steps.bidClosed')}</S.StatusTitle>,
      icon: <LockOutlined />,
      key: BidStatus.CLOSED,
    },
    {
      title: <S.StatusTitle>{t('pages.viewRfq.steps.inReview')}</S.StatusTitle>,
      icon: <FundViewOutlined />,
      key: BidStatus.IN_REVIEW,
    },
    {
      title: <S.StatusTitle>{t('pages.viewRfq.steps.result')}</S.StatusTitle>,
      icon: <TrophyOutlined />,
      key: BidStatus.RESULT,
    },
  ];
  const [steps, setSteps] = useState<IStepsStatus[]>(initialSteps);

  //alt group detail
  const [isLoadingDetails, setIsLoadingDetails] = useState<boolean>(false);
  const [altGroupDetail, setAltGroupDetail] = useState<IAltGroupDetails>();
  const [suppliers, setSuppliers] = useState<ISupplierResponse[]>([]);
  const [tabProjects, setTabProjects] = useState<TabsProps['items']>();
  const [selectedAltGroup, setSelectedAltGroup] = useState<ISelectedAltGroup>({
    altGroup: '',
    category: '',
  });
  const [selectedProject, setSelectedProject] = useState<string>();
  const [isOpenCommentsModal, setIsOpenCommentsModal] = useState<ICommentModal>({
    isOpen: false,
    comments: undefined,
  });
  const [isOpenNewRoundModal, setIsOpenNewRoundModal] = useState<{
    bidId: string;
    isOpen: boolean;
  }>({ bidId: '', isOpen: false });
  const [loadingNewRound, setLoadingNewRound] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState<string>('NET');
  const [optionChanged, setOptionChanged] = useState<boolean>(false);
  const toggleOptions = [
    { label: 'GROSS', value: 'GROSS' },
    { label: 'NET', value: 'NET' },
  ];

  const completeSocketURL = bidId ? `${import.meta.env.VITE_API_WEBSOCKET}?channel=${bidId}` : null;

  const { sendJsonMessage, lastMessage, lastJsonMessage, sendMessage } = useWebSocket(
    `${import.meta.env.VITE_API_WEBSOCKET}?channel=${bidId}`,
    {
      shouldReconnect: () => true,
      retryOnError: true,
    },
    !!completeSocketURL
  );

  const closeWebSocketConnection = (channel: string) => {
    sendJsonMessage({ action: 'unsubscribe', channel });
  };

  const handleToggleGrossNet = (option: string, suppliersToOrder: ISupplierResponse[]) => {
    const copySuppliers = suppliersToOrder;

    if (option === 'NET') {
      //em caso de visualização NET, os suppliers sem NET PRICE são separados do array original por não ocuparem uma posição real
      const suppliersWithoutNetPrice = copySuppliers.filter(
        (supplier) => supplier.netPrice === null
      );
      //os suppliers com NET PRICE são ordenados de acordo com a posição que ocupam
      const suppliersWithNetPrice = copySuppliers
        .filter((supplier) => supplier.netPrice !== null)
        .sort((a, b) => a.position - b.position);
      //retorna primeiro os suppliers ordenados e depois os suppliers sem NET PRICE, de forma aleatória
      return setSuppliers([...suppliersWithNetPrice, ...suppliersWithoutNetPrice]);
    }

    //em caso de visualização GROSS, os suppliers sem GROSS PRICE são separados do array original por não ocuparem uma posição real
    const suppliersWithoutGrossPrice = copySuppliers.filter(
      (supplier) => supplier.grossPrice === null
    );
    //os suppliers com GROSS PRICE são ordenados de acordo com a posição que ocupam
    const suppliersWithGrossPrice = copySuppliers
      .filter((supplier) => supplier.grossPrice !== null)
      .sort((a, b) => a.positionByGross - b.positionByGross);

    //retorna primeiro os suppliers ordenados e depois os suppliers sem GROSS PRICE, de forma aleatória
    return setSuppliers([...suppliersWithGrossPrice, ...suppliersWithoutGrossPrice]);
  };

  const onChangeOption = ({ target: { value } }: RadioChangeEvent) => {
    setSelectedOption(value);
    setOptionChanged(true);
    handleToggleGrossNet(value, altGroupDetail?.suppliers || []);
  };

  const updateStatus = (data: ITimeLine[]) => {
    const stepsCopy = initialSteps;
    let currentIndex = 0;

    data.forEach((item) => {
      currentIndex = steps.findIndex((step) => step.key === item.status);
      if (currentIndex > -1) {
        stepsCopy[currentIndex].status = 'finish';
        stepsCopy[currentIndex].description = (
          <div>
            <span>
              {dayjs(item.dateTime).format('MM.DD.YYYY')} - {dayjs(item.dateTime).format('HH:mm')}
            </span>
          </div>
        );
      }
    });
    if (data.some((item) => item.status === 'CANCELED')) {
      const lastItem = data[data.length - 1];
      const cancelStatus = stepsCopy.filter((status) => status.status === 'finish');
      cancelStatus.push({
        title: <S.StatusTitle>{t('pages.viewRfq.steps.cancel')}</S.StatusTitle>,
        icon: <S.StatusIcons />,
        key: BidStatus.CANCELED,
        status: 'finish',
        description: (
          <div>
            <span>
              {dayjs(lastItem.dateTime).format('MM.DD.YYYY')} -{' '}
              {dayjs(lastItem.dateTime).format('HH:mm')}
            </span>
          </div>
        ),
      });
      return setSteps(cancelStatus);
    }
    setSteps(stepsCopy);
  };

  const fetchStatus = async () => {
    if (!bidId) return;
    setIsLoading(true);
    try {
      const { data, status } = await api.patch(
        getURI(`${services.rfq}/bid/status`, { idBid: bidId })
      );
      if (status === 200) {
        updateStatus(data);
      }
      return;
    } catch (error) {
      openAlert('error', t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const formatProjectTabs = (projects: string[]) => {
    setTabProjects(
      projects.map((project) => {
        return {
          key: project,
          label: project,
        };
      })
    );
  };

  const getSummaryInformations = async () => {
    try {
      setIsLoading(true);
      const { status, data }: { status: HttpStatusCode; data: IBidInformations } = await api.get(
        `${services.rfq}/bid/admin/${bidId}`
      );
      if (status === 200) {
        return setbidInformations(data);
      }
      setbidInformations(undefined);
    } catch (err) {
      const error = err as IError;
      openAlert('error', error.errorResponse.messageDetail || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const createNewRound = async () => {
    try {
      setLoadingNewRound(true);
      const { status } = await api.patch(`${services.rfq}/bid/newRound?idBid=${bidId}`);
      if (status === 200 || status === 204) {
        history.push('/rfq');
      }
    } catch (error: any) {
      openAlert('error', error.message || t('toast.errorOnSave'));
    } finally {
      setLoadingNewRound(false);
      setIsOpenNewRoundModal({ bidId: '', isOpen: false });
    }
  };

  const handleSelectAltGroup = (altGroup: string, category: string) => {
    if (altGroup === selectedAltGroup.altGroup && category === selectedAltGroup.category) {
      setSelectedAltGroup({
        altGroup: '',
        category: '',
      });
    } else {
      setSelectedAltGroup({
        altGroup,
        category,
      });
    }
    setSelectedProject(undefined);
    setTabProjects(undefined);
  };

  const fetchAltGroup = async () => {
    try {
      setIsLoadingDetails(true);
      const { status, data } = await api.get(
        getURI(`${services.rfq}/bid/admin/${bidId}/detail`, {
          ...selectedAltGroup,
          project: selectedProject,
        })
      );
      if (status === 200) {
        if (!selectedProject) formatProjectTabs(data.projects);
        setSelectedProject(data.currentProject);
        setAltGroupDetail(data);
        setSuppliers(data.suppliers);
        setSelectedOption('NET');
        handleToggleGrossNet('NET', data.suppliers);
      }
      if (status === 204) {
        setTabProjects(undefined);
        setSelectedProject(undefined);
        setAltGroupDetail(undefined);
        setSuppliers([]);
      }
    } catch (err) {
      const error = err as IError;
      openAlert('error', error.errorResponse.messageDetail || t('toast.errorOnList'));
    } finally {
      setIsLoadingDetails(false);
    }
  };

  const handleCancelBid = async (message: string) => {
    try {
      setIsLoading(true);
      const { status }: { status: HttpStatusCode } = await api.patch(
        getURI(`${services.rfq}/bid/status`, {
          idBid: bidId,
          canceled: true,
          cancelMessage: encodeURI(message),
        })
      );
      if (status === 200) {
        setIsOpenCancelBidModal(false);
        getSummaryInformations();
        fetchStatus();
        openAlert('success', t('toast.bidCanceled'));
      }
    } catch (err) {
      const error = err as IError;
      openAlert('error', error.errorResponse.messageDetail || t('toast.errorOnList'));
    } finally {
      setIsLoading(false);
    }
  };

  const verifyAltGroup = (updatedAltGroup: string) => {
    if (updatedAltGroup === selectedAltGroup.altGroup) {
      fetchAltGroup();
    }
  };

  useEffect(() => {
    if (lastMessage !== null || (lastJsonMessage as { altGroup: any }) !== null) {
      verifyAltGroup((lastJsonMessage as { altGroup: any }).altGroup);
    }
  }, [lastMessage, lastJsonMessage]);

  useEffect(() => {
    if (selectedProject) fetchAltGroup();
  }, [selectedProject]);

  useEffect(() => {
    if (selectedAltGroup.altGroup && selectedAltGroup.category) fetchAltGroup();
  }, [selectedAltGroup]);

  useEffect(() => {
    if (bidId) {
      Promise.all([getSummaryInformations(), fetchStatus()]);
    }
  }, [bidId]);

  useEffect(() => {
    if (search) {
      const id = search.split('=')[1];
      setBidId(id);
    }
  }, []);

  const onNavigateOut = history.listen((location) => {
    if (location.pathname !== pathname) {
      closeWebSocketConnection(search.split('=')[1]);
    }
  });

  const handleNavigateAwards = () => {
    history.push(`/awards/${bidId}`);
  };

  useEffect(() => {
    return () => {
      onNavigateOut();
    };
  }, []);

  return {
    bidId,
    bidInformations,
    loading,
    steps,
    isOpenCommentsModal,
    selectedAltGroup,
    tabProjects,
    isLoadingDetails,
    altGroupDetail,
    suppliers,
    selectedProject,
    isOpenCancelBidModal,
    setIsOpenCancelBidModal,
    setSelectedProject,
    setIsOpenCommentsModal,
    handleSelectAltGroup,
    handleCancelBid,
    toggleOptions,
    selectedOption,
    onChangeOption,
    optionChanged,
    setOptionChanged,
    handleNavigateAwards,
    createNewRound,
    setIsOpenNewRoundModal,
    isOpenNewRoundModal,
    fetchStatus,
    loadingNewRound,
  };
}
