// eslint-disable-next-line no-shadow
export enum ListTypeOptions {
  'Quotes' = 1,
  'Imports Components Price' = 2,
  'Local Components Price' = 3,
  'Packaging Price' = 4,
  'Average' = 5,
  'Forecast' = 8,
  'Share Proportion' = 7,
  'In Out' = 9,
}

// eslint-disable-next-line no-shadow
export enum Months {
  'JANUARY' = 1,
  'FEBRUARY' = 2,
  'MARCH' = 3,
  'APRIL' = 4,
  'MAY' = 5,
  'JUNE' = 6,
  'JULY' = 7,
  'AUGUST' = 8,
  'SEPTEMBER' = 9,
  'OCTOBER' = 10,
  'NOVEMBER' = 11,
  'DECEMBER' = 12,
}

// eslint-disable-next-line no-shadow
export enum BidStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  ROUND_OPEN = 'ROUND_OPEN',
  QUOTATION_SENT = 'QUOTATION_SENT',
  CLOSED = 'CLOSED',
  IN_REVIEW = 'IN_REVIEW',
  CONCLUDED = 'CONCLUDED',
  CANCELED = 'CANCELED',
  RESULT = 'RESULT',
}

// eslint-disable-next-line no-shadow
export enum QualificationStatus {
  REQUESTED = 'Requested',
  SAMPLE_SUBMISSION_PENDING = 'Sample Submission Pending',
  SAMPLE_SENT = 'Sample Send',
  RECEIVED_BY_ODM = 'Received by ODM',
  TESTING_IN_PROGRESS = 'Testing in Progress',
  RESULT_AVAILABLE = 'Result Available',
}

// eslint-disable-next-line no-shadow
export enum QualificationTagStatus {
  'Sent Supplier' = 'Sent Supplier',
  'Sent ODM' = 'Sent ODM',
  Cancel = 'Cancel',
  Concluded = 'Concluded',
}

// eslint-disable-next-line no-shadow
export enum TrackingTagEnum {
  New = 'New',
  Updated = 'Updated',
  Delivered = 'Delivered',
}

// eslint-disable-next-line no-shadow
export enum FreightForwarderEnum {
  Fedex = 'Fedex',
  DHL = 'DHL',
}
